import React from 'react'
import { motion } from 'framer-motion'

const svgVariants = {
    hidden: {},
    visible: {
        transition: {
            staggerChildren: 2.5
        }
    }
}

const starsGroupVariants = {
    hidden: {},
    visible: {
        transition: {
            staggerChildren: 0.5
        }
    }
}

const starsVariants = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: {
            duration: 1,
            repeat: Infinity,
            repeatType: "reverse",
            ease: "easeInOut"
        }
    }
}

const cometGroupVariants = {
    hidden: {
        opacity: 0
    },
    visible: {
        opacity: 1,
        transition: {
            duration: 2.5,
            repeat: Infinity,
            repeatType: "reverse",
            ease: "easeInOut"
        }
    }
}

const cometVariants = {
    hidden: {
        pathLength: 0,
    },
    visible: {
        pathLength: 1,
        transition: {
            duration: 5,
            repeat: Infinity,
            repeatType: "loop",
            ease: "easeInOut"
        }
    }
}

const rocketGroupVariants = {
    hidden: {
        // x: -0.8,
        y: 0
    },
    visible: {
        // x: 0.8,
        y: -1000,
        transition: {
            // duration: 0.07,
            duration: 1.5,
            repeat: Infinity,
            repeatDelay: 5,
            repeatType: "reverse",
            ease: "circIn"
        }
    }
}

const SvgRelaunchDay = ({ themeColor }) => {
    return (
        <motion.svg width="754" height="751" viewBox="0 0 754 751" fill="none" xmlns="http://www.w3.org/2000/svg"
            variants={svgVariants}
            initial="hidden"
            animate="visible"
            exit={{
                opacity: 0, transition: {
                    duration: 0.2
                }
            }}
        >
            <path id="Vector"
                d="M754 377C754.084 467.413 721.641 554.84 662.599 623.312C603.556 691.785 521.852 736.734 432.41 749.95H330.3C330.14 750.09 324.48 750.36 324.48 750.36C323.53 750.23 322.57 750.09 321.62 749.95C314.35 748.88 307.147 747.603 300.01 746.12C272.065 740.335 244.877 731.36 218.98 719.37C218.71 719.25 218.45 719.12 218.18 719C203.749 712.302 189.763 704.683 176.31 696.19C175.97 695.98 175.64 695.77 175.31 695.56C174.74 695.2 174.18 694.85 173.62 694.48C173.44 694.37 173.26 694.26 173.08 694.14C161.053 686.393 149.477 677.966 138.41 668.9C137.87 668.47 137.33 668.02 136.79 667.58C134.62 665.78 132.47 663.96 130.34 662.12C130.32 662.11 130.31 662.09 130.29 662.08C119.406 652.647 109.066 642.604 99.32 632C99.29 631.96 99.25 631.93 99.22 631.89C56.4076 585.321 26.0279 528.71 10.89 467.29C10.77 466.85 10.67 466.42 10.56 465.98C3.52589 436.843 -0.0189875 406.974 1.49567e-05 377C1.49567e-05 168.79 168.79 0 377 0C585.21 0 754 168.79 754 377Z"
                fill="#3F3D56" />
            <g id="moon">
                <path id="Vector_2"
                    d="M146.933 311.039C183.49 311.039 213.126 281.404 213.126 244.847C213.126 208.29 183.49 178.654 146.933 178.654C110.376 178.654 80.7407 208.29 80.7407 244.847C80.7407 281.404 110.376 311.039 146.933 311.039Z"
                    fill="#E6E6E6" />
                <path id="Vector_3"
                    d="M140.423 206.867C144.618 206.867 148.018 203.467 148.018 199.271C148.018 195.076 144.618 191.676 140.423 191.676C136.227 191.676 132.827 195.076 132.827 199.271C132.827 203.467 136.227 206.867 140.423 206.867Z"
                    fill="#CBCBCB" />
                <path id="Vector_4"
                    d="M153.444 282.826C157.639 282.826 161.04 279.425 161.04 275.23C161.04 271.035 157.639 267.634 153.444 267.634C149.249 267.634 145.848 271.035 145.848 275.23C145.848 279.425 149.249 282.826 153.444 282.826Z"
                    fill="#CBCBCB" />
                <path id="Vector_5"
                    d="M182.742 238.336C185.739 238.336 188.168 235.907 188.168 232.91C188.168 229.914 185.739 227.485 182.742 227.485C179.746 227.485 177.317 229.914 177.317 232.91C177.317 235.907 179.746 238.336 182.742 238.336Z"
                    fill="#CBCBCB" />
                <path id="Vector_6"
                    d="M120.89 267.634C130.479 267.634 138.252 259.861 138.252 250.272C138.252 240.684 130.479 232.91 120.89 232.91C111.302 232.91 103.528 240.684 103.528 250.272C103.528 259.861 111.302 267.634 120.89 267.634Z"
                    fill="#CBCBCB" />
            </g>
            <g id="whitePlanets">
                <path id="Vector_7"
                    d="M342.279 100.556C338.417 103.129 334.464 97.0046 338.401 94.5456C342.262 91.9726 346.215 98.097 342.279 100.556Z"
                    fill="#E6E6E6"
                />
            </g>
            <g id="orangePlanets">
                <path id="Vector_8"
                    d="M252.015 352.789C253.99 352.789 255.591 351.188 255.591 349.213C255.591 347.238 253.99 345.636 252.015 345.636C250.04 345.636 248.438 347.238 248.438 349.213C248.438 351.188 250.04 352.789 252.015 352.789Z"
                    fill={themeColor}
                />
                <path id="Vector_9"
                    d="M455.34 192.127C457.315 192.127 458.916 190.526 458.916 188.551C458.916 186.576 457.315 184.974 455.34 184.974C453.364 184.974 451.763 186.576 451.763 188.551C451.763 190.526 453.364 192.127 455.34 192.127Z"
                    fill={themeColor}
                />
            </g>
            <motion.g id="whiteStars"
                variants={starsGroupVariants}
            >
                <motion.path id="Vector_10"
                    d="M686.557 230.728L684.1 230.189L684.638 227.731L683 227.372L682.461 229.83L680.004 229.292L679.645 230.93L682.102 231.469L681.564 233.926L683.202 234.285L683.741 231.827L686.198 232.366L686.557 230.728Z"
                    fill="#E6E6E6"
                    variants={starsVariants}
                />
                <motion.path id="Vector_11"
                    d="M283.012 401.093L281.227 402.866L279.454 401.081L278.264 402.263L280.037 404.048L278.252 405.821L279.434 407.011L281.219 405.238L282.992 407.023L284.182 405.841L282.409 404.056L284.194 402.283L283.012 401.093Z"
                    fill="#E6E6E6"
                    variants={starsVariants}
                />
                <motion.path id="Vector_12"
                    d="M297.318 297.375L295.533 299.148L293.76 297.363L292.57 298.545L294.343 300.33L292.558 302.103L293.74 303.293L295.525 301.52L297.298 303.305L298.488 302.123L296.715 300.338L298.5 298.565L297.318 297.375Z"
                    fill="#E6E6E6"
                    variants={starsVariants}
                />
                <motion.path id="Vector_13"
                    d="M118.557 366.728L116.1 366.189L116.638 363.731L115 363.372L114.461 365.83L112.004 365.292L111.645 366.93L114.102 367.469L113.564 369.926L115.202 370.285L115.741 367.827L118.198 368.366L118.557 366.728Z"
                    fill="#E6E6E6"
                    variants={starsVariants}
                />
                <motion.path id="Vector_14"
                    d="M494.557 71.728L492.1 71.189L492.638 68.731L491 68.372L490.461 70.83L488.004 70.292L487.645 71.93L490.102 72.469L489.564 74.926L491.202 75.285L491.741 72.827L494.198 73.366L494.557 71.728Z"
                    fill="#E6E6E6"
                    variants={starsVariants}
                />
                <motion.path id="Vector_15"
                    d="M222.46 110.506L220.003 109.967L220.541 107.51L218.903 107.151L218.364 109.608L215.906 109.07L215.547 110.708L218.005 111.247L217.467 113.705L219.105 114.063L219.644 111.606L222.101 112.144L222.46 110.506Z"
                    fill="#E6E6E6"
                    variants={starsVariants}
                />
                <motion.path id="Vector_16"
                    d="M428.557 242.728L426.1 242.189L426.638 239.731L425 239.372L424.461 241.83L422.004 241.292L421.645 242.93L424.102 243.469L423.564 245.926L425.202 246.285L425.741 243.827L428.198 244.366L428.557 242.728Z"
                    fill="#E6E6E6"
                    variants={starsVariants}
                />
                <motion.path id="Vector_17"
                    d="M523.46 198.506L521.003 197.967L521.541 195.51L519.903 195.151L519.364 197.608L516.906 197.07L516.547 198.708L519.005 199.247L518.467 201.705L520.105 202.063L520.644 199.606L523.101 200.144L523.46 198.506Z"
                    fill="#E6E6E6"
                    variants={starsVariants}
                />
            </motion.g>
            <motion.g id="cometLine1"
                variants={cometGroupVariants}
            >
                <motion.path id="Vector 1" d="M443.5 118L357 219" stroke="white" stroke-opacity="0.8" stroke-width="2"
                    variants={cometVariants}
                    style={{ originZ: 0.5 }}
                />
            </motion.g>
            <motion.g id="cometLine2"
                variants={cometGroupVariants}
            >
                <motion.path id="Vector 2" d="M495.5 332.5L409 433.5" stroke="white" stroke-opacity="0.8" stroke-width="2"
                    variants={cometVariants}
                    style={{ originZ: 0.5 }}
                />
            </motion.g>
            <g id="tower">
                <path id="Vector_20" d="M649.38 245.25V637.65C625.053 663.068 597.303 684.972 566.93 702.73V245.25H649.38Z"
                    fill="#2F2E41" />
                <path id="Vector_21" d="M688.521 295.224H634.387V349.358H688.521V295.224Z" fill="#2F2E41" />
                <path id="Vector_22" d="M614.399 114.5H601.906V256.081H614.399V114.5Z" fill="#2F2E41" />
                <g id="Group" opacity="0.4">
                    <path id="Vector_23" opacity="0.4"
                        d="M573.93 245.25H566.93V702.73C569.282 701.356 571.615 699.954 573.93 698.525V245.25Z"
                        fill="#E6E6E6" />
                    <path id="Vector_24" opacity="0.4" d="M608.906 114.5H601.906V245.25H608.906V114.5Z" fill="#E6E6E6" />
                </g>
                <path id="Vector_25" d="M628.14 309.798H583.168V334.783H628.14V309.798Z" fill={themeColor} />
                <path id="Vector_26"
                    d="M606.5 125.5C612.575 125.5 617.5 120.575 617.5 114.5C617.5 108.425 612.575 103.5 606.5 103.5C600.425 103.5 595.5 108.425 595.5 114.5C595.5 120.575 600.425 125.5 606.5 125.5Z"
                    fill={themeColor} />
            </g>
            <motion.g id="rocket"
                variants={rocketGroupVariants}
                style={{ x: -18 }}
            >
                <path id="Vector_27" d="M420.91 336.41H386.81V749.95H420.91V336.41Z" fill="#2F2E41" />
                <path id="Vector_28"
                    d="M409.95 488.23L409.88 500.61L409.05 650.87L408.95 668.78L408.73 709.14L408.51 749.95H406.31L406.53 709.14L406.74 671.44L406.85 650.5L407.68 500.64L407.75 488.22L409.95 488.23Z"
                    fill={themeColor} />
                <path id="Vector_29"
                    d="M427.031 341.91H380.827C380.156 334.672 381.825 327.382 386.121 320.034L387.427 311.107H419.33L420.835 319.921C425.305 325.877 427.037 333.397 427.031 341.91Z"
                    fill="#2F2E41" />
                <path id="Vector_30"
                    d="M420.318 312.207H387.396C387.064 312.207 386.735 312.132 386.436 311.987C386.137 311.842 385.874 311.63 385.668 311.369C385.463 311.108 385.318 310.804 385.247 310.479C385.176 310.154 385.179 309.817 385.257 309.494L390.801 286.392C390.917 285.911 391.19 285.483 391.579 285.177C391.967 284.871 392.446 284.705 392.941 284.705H414.774C415.268 284.705 415.748 284.871 416.136 285.177C416.524 285.483 416.798 285.911 416.913 286.392L422.458 309.494C422.535 309.817 422.538 310.154 422.467 310.479C422.396 310.804 422.252 311.108 422.046 311.369C421.84 311.63 421.578 311.842 421.278 311.987C420.979 312.132 420.651 312.207 420.318 312.207V312.207Z"
                    fill="#2F2E41" />
                <path id="Vector_31"
                    d="M403.86 273.15C400.796 273.154 397.859 274.373 395.693 276.54C393.528 278.708 392.311 281.646 392.31 284.71V296.26H415.41V284.71C415.409 281.646 414.192 278.708 412.027 276.54C409.861 274.373 406.924 273.154 403.86 273.15V273.15Z"
                    fill="#2F2E41" />
                <path id="Vector_32" d="M391.051 622.109L312.765 723.714L323.592 726.213L384.388 647.927H391.051V622.109Z"
                    fill="#2F2E41" />
                <path id="Vector_33" d="M419.831 622.109L498.117 723.714L487.29 726.213L426.494 647.927H419.831V622.109Z"
                    fill="#2F2E41" />
                <path id="Vector_34" d="M391.494 646.969H385.494V748.992H391.494V646.969Z" fill="#E6E6E6" />
                <path id="Vector_35"
                    d="M405.54 272.6C403.089 273.263 400.924 274.714 399.379 276.729C397.834 278.744 396.995 281.211 396.99 283.75V283.85C396.623 283.96 396.291 284.163 396.026 284.44C395.761 284.717 395.573 285.058 395.48 285.43L389.94 308.54C389.865 308.852 389.86 309.176 389.925 309.49C389.99 309.804 390.123 310.099 390.315 310.356C390.507 310.613 390.752 310.824 391.035 310.975C391.318 311.126 391.63 311.213 391.95 311.23L391.91 311.5L391.76 312.5L389.5 319.5C385.21 326.84 384.84 333.71 385.51 340.95H391.49V621.46L313.45 722.76L319.19 724.08L318.28 725.26L307.45 722.76L385.49 621.46V340.95L380.5 340.5C379.83 333.26 381.21 326.84 385.5 319.5L385.76 312.5L385.91 311.5L385.95 311.23C385.63 311.213 385.318 311.126 385.035 310.975C384.752 310.824 384.507 310.613 384.315 310.356C384.123 310.099 383.99 309.804 383.925 309.49C383.86 309.176 383.865 308.852 383.94 308.54L389.48 285.43C389.573 285.058 389.761 284.717 390.026 284.44C390.291 284.163 390.623 283.96 390.99 283.85V283.75C390.991 280.686 392.208 277.748 394.373 275.58C396.539 273.413 399.476 272.194 402.54 272.19C403.554 272.193 404.563 272.331 405.54 272.6V272.6Z"
                    fill="#E6E6E6" />
                <path id="Vector_36" d="M431.177 646.969H425.594V647.506L485.974 725.255L491.062 724.081L431.177 646.969Z"
                    fill="#E6E6E6" />
                <path id="Vector_37"
                    d="M385.49 340.95H379.51C378.84 333.71 380.51 326.42 384.8 319.08L385.76 312.5L385.91 311.5H391.91L391.76 312.5L390.8 319.08C386.51 326.42 384.84 333.71 385.51 340.95H391.49"
                    fill={themeColor} />
                <path id="Vector_38" d="M391.49 427.5H385.49V500.5H391.49V427.5Z" fill={themeColor} />
            </motion.g>
            <path id="Vector_39"
                d="M514.88 709.14V727.99C488.317 738.429 460.647 745.796 432.41 749.95H309.41C306.26 749.38 303.12 748.77 300.01 748.12V709.14H514.88Z"
                fill="#2F2E41"
            />
            <g id="plant">
                <path id="Vector_40"
                    d="M409.05 650.87C408.35 650.75 407.61 650.63 406.85 650.5C402.38 649.78 396.99 648.96 390.81 648.11C390.39 648.05 389.96 647.99 389.52 647.93C384.42 647.24 378.81 646.54 372.76 645.85C339.59 642.12 293.26 639.13 245.98 643.24C247.15 638.54 248.23 633.84 249.25 629.16L203.97 636.17L252.83 611.32C257.433 585.791 260.598 560.024 262.31 534.14L237.85 537.92L262.86 525.2C264.65 493.17 263.81 471.85 263.81 471.85C263.81 471.85 182 514.38 116.58 579.38C114.18 570.92 111.55 562.57 108.75 554.37L73.61 583.77L102.58 537.25C93.3307 513.014 82.7205 489.32 70.8 466.28L51.82 482.16L66.66 458.34C51.64 429.99 39.9 412.17 39.9 412.17C39.9 412.17 26.51 434.11 10.56 467.98C25.6024 529.947 56.1448 587.074 99.32 634C109.08 644.62 119.437 654.677 130.34 664.12C132.47 665.96 134.62 667.78 136.79 669.58C148.348 679.147 160.466 688.016 173.08 696.14C173.82 696.62 174.56 697.09 175.31 697.56C213.489 721.792 255.736 738.921 300.01 748.12C303.12 748.77 306.26 749.38 309.41 749.95C310.18 750.09 310.95 750.23 311.72 750.36H324.48C324.48 750.36 330.14 750.09 330.3 749.95C346.15 736.08 360.52 722.06 372.91 709.14C375.73 706.21 378.44 703.33 381.05 700.53L359.67 688.06L387.11 693.94C388.37 692.55 389.61 691.18 390.81 689.84C396.97 682.96 402.31 676.73 406.74 671.44C407.5 670.52 408.24 669.64 408.95 668.78C417.02 659.04 421.46 653.1 421.46 653.1C421.46 653.1 417 652.19 409.05 650.87Z"
                    fill="#2F2E41" />
            </g>
            <defs>
                <linearGradient id="paint0_linear" x1="409.931" y1="382.182" x2="496.741" y2="382.182"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="white" stopOpacity="0.3" />
                </linearGradient>
                <linearGradient id="paint1_linear" x1="50612.9" y1="24816.9" x2="58148.8" y2="24816.9"
                    gradientUnits="userSpaceOnUse">
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="white" stopOpacity="0.3" />
                </linearGradient>
                <clipPath id="clip0">
                    <rect width="754" height="750.36" fill="white" />
                </clipPath>
            </defs>
        </motion.svg>
    )
}

export default SvgRelaunchDay

SvgRelaunchDay.defaultProps = {
    themeColor: "#FF8C00"
}