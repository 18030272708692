import React from "react";
import { graphql } from "gatsby";
import tw, { styled } from "twin.macro";
import { createHTML } from "../utils";

import Layout from "../components/Layout";
import Section from "../components/Section";
import InlineIconTextList from "../components/InlineIconTextList";
import Hero from "../components/Hero";
import Atg from "../components/Atg";
import AlternatingIconTextList from "../components/AlternatingIconTextList";
import ContactUs from "../components/ContactUs";

const StyledSection = styled(Section)`
  ${({ noMb }) => noMb && tw`mb-0`}
`;

const Header = styled.h3`
  ${tw`
        text-center font-bold mb-16
    `}

  ${({ hasSub }) =>
    hasSub &&
    tw`
        mb-12
    `}
`;

const Subheader = styled.h6`
  ${tw`
        text-center mb-16
    `}
`;

const WhyWeAreDifferent = styled(InlineIconTextList)`
  ${tw`
        max-w-screen-2xl
        px-6 md:px-12
    `}

  grid-column: full;
`;

const MainPageOne = ({
  data: {
    markdownRemark: {
      frontmatter: {
        seo,
        hero,
        contactSectionTop,
        inlineSection,
        atgSection,
        alternatingSection,
        contactSectionBottom,
      },
    },
  },
}) => {
  return (
    <Layout title={seo.title} description={seo.description}>
      <StyledSection noMb={contactSectionTop && contactSectionTop.visible}>
        <Hero data={hero} />
      </StyledSection>

      {contactSectionTop && contactSectionTop.visible && (
        <Section>
          <ContactUs
            iconColor={contactSectionTop.color}
            header={contactSectionTop.header}
            subheader={contactSectionTop.subheader}
            buttonLabel={contactSectionTop.buttonLabel}
            formType={contactSectionTop.formType}
          />
        </Section>
      )}

      <Section>
        <Header>{inlineSection.header}</Header>
        {inlineSection.subheader && (
          <Subheader
            dangerouslySetInnerHTML={createHTML(inlineSection.subheader)}
          />
        )}
        <WhyWeAreDifferent data={inlineSection.data} />
      </Section>

      <Section>
        <Header hasSub>{alternatingSection.header}</Header>
        <Subheader
          dangerouslySetInnerHTML={createHTML(alternatingSection.subheader)}
        />
        <AlternatingIconTextList
          data={alternatingSection.data}
          linkHoverColor={alternatingSection.hoverColor}
        />
      </Section>

      {atgSection.visible && (
        <Section>
          <Atg
            largerVersion={atgSection.largerVersion}
            themeColor={atgSection.color}
          />
        </Section>
      )}

      {contactSectionBottom.visible && (
        <Section>
          <ContactUs
            iconColor={contactSectionBottom.color}
            header={contactSectionBottom.header}
            subheader={contactSectionBottom.subheader}
            buttonLabel={contactSectionBottom.buttonLabel}
            formType={contactSectionBottom.formType}
          />
        </Section>
      )}
    </Layout>
  );
};

export default MainPageOne;

export const pageQuery = graphql`
  query mainPageOneById($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        seo {
          title
          description
        }
        hero {
          preheader
          header {
            text
            gradient {
              top
              bottom
            }
          }
          subheader
          sideImages {
            left {
              relativePath
            }
            right {
              relativePath
            }
          }
        }
        contactSectionTop {
          visible
          color
          header
          subheader
          buttonLabel
          formType {
            type
            preSelectedServices
          }
        }
        inlineSection {
          header
          subheader
          data {
            main
            sub
            image {
              relativePath
            }
          }
        }
        atgSection {
          visible
          largerVersion
          color
        }
        alternatingSection {
          header
          subheader
          hoverColor
          data {
            main
            sub
            list {
              text
              url
            }
            url
            image {
              relativePath
            }
          }
        }
        contactSectionBottom {
          visible
          color
          header
          subheader
          buttonLabel
          formType {
            type
            preSelectedServices
          }
        }
      }
    }
  }
`;
