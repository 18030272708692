import React from 'react'
import { Link } from "gatsby"
import tw, { styled, css } from 'twin.macro'

import SvgRelaunchDay from './SvgRelaunchDay'
import Button from './Button'

const Wrapper = styled.div`
    ${tw`
        flex flex-col 
        items-center
        mt-0 
    `}

    ${({ largerVersion }) => largerVersion && tw`
        sm:mt-14 md:mt-16 lg:mt-20
    `}
`

const Header = styled.div`
    ${tw`
        font-extrabold text-center
        tracking-wider
        mt-6
        max-w-screen-md
        z-10
    `}

    color: ${props => props.color || "darkorange"};
    text-shadow: 0px 5px #3F3D56;
`

const ImageContainerOuter = styled.div`
    ${tw`
        relative w-full
        mb-6
    `}
`

const ImageContainerInner = styled.div`
    ${tw`
        relative overflow-hidden
        left-1/2 
        transform -translate-x-1/2 
        mt-6 
    `}

    max-width: 450px;

    ${({ largerVersion }) => largerVersion && css`
        ${tw`
            sm:-mt-52
        `}

        max-width: 650px;
    `}
    
    > svg {
        ${tw`
            w-full h-full
        `}
    }
`

const Description = styled.div`
    ${tw`
        text-center text-xl
        whitespace-pre-wrap
        z-10
    `}
`

const AtgLink = styled(Link)`
    ${tw`
        mt-12 mb-0 mx-auto
        z-10
    `}
`

const Atg = ({ largerVersion, themeColor }) => {
    return (
        <Wrapper largerVersion={largerVersion}>
            <Header color={themeColor}>
                <h4>We Are An</h4>
                <h3>Advanced Technology Group</h3>
            </Header>
            <ImageContainerOuter>
                <ImageContainerInner largerVersion={largerVersion}>
                    <SvgRelaunchDay themeColor={themeColor} />
                </ImageContainerInner>
            </ImageContainerOuter>
            <Description largerVersion={largerVersion}>
                We build innovative software and technology products that push beyond the limits of technological uncertainty to <i>disrupt industries</i> and <i>delight customers</i>.
            </Description>
            <AtgLink to="/build/advanced-technology-group">
                <Button>Learn more</Button>
            </AtgLink>
        </Wrapper>
    )
}

export default Atg
